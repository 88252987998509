import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import TitledWithBg from './TitleWithBg';

const TitleIconBox = ({ title, content, icon, id, bgColor }) => {
  return (
    <div className={`titled-icon-box ${bgColor}`}>
      <div className="image-wrapper">
        <img src={icon} alt={`icon-${id}`} />
      </div>
      <div className="content-wrapper">
        <p className="title">{title}</p>
        <p className="content">{content}</p>
      </div>
    </div>
  );
};

TitleIconBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  bgColor: PropTypes.string,
};

function TitledBoxesWithBg({ title, subTitle, description, boxDataList = [] }) {
  return (
    <section id="titled-boxes-bg-section" className="titled-boxes-bg-section">
      <Container>
        <TitledWithBg title={title} subTitle={subTitle} />
        {description && <p className="description">{description}</p>}
        <div className="titled-boxes-content">
          <Row className="titled-boxes-row">
            {boxDataList.map((item) => {
              return (
                <Col key={item.id} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TitleIconBox {...item} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
}

TitledBoxesWithBg.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  boxDataList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      icon: PropTypes.string,
      id: PropTypes.string,
      bgColor: PropTypes.string,
    })
  ),
};

export default TitledBoxesWithBg;
