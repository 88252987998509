import React from 'react';
import Banner2 from '../components/Banner2';
import CasesBanner from '../images/cases-banner-2.svg';
import Layout from '../components/Layout';
import TitledBoxesWithBg from '../components/TitledBoxesWithBg';
import CasesPain1 from '../images/cases-pain-5.svg';
import CasesPain2 from '../images/cases-pain-6.svg';
import CasesPain3 from '../images/cases-pain-7.svg';
import CasesPain4 from '../images/cases-pain-8.svg';
import StepSolutions from '../components/StepSolutions';
import Step1 from '../images/step_2_1.svg';
import Step2 from '../images/step_2_2.svg';
import Step3 from '../images/step_2_3.svg';
import Step4 from '../images/step_2_4.svg';
import StepsTopper from '../images/steps-topper-2.png';

const boxDataList = [
  {
    title: '难以评估营销效果',
    content: `无来源标记体系，无法追踪线索全生命周期路径；\n营销数据分散，难以用客观数据评估渠道效果`,
    icon: CasesPain1,
    id: 'cases-1',
    bgColor: 'white',
  },
  {
    title: '线索特征和指标不清晰',
    content: `无法自动进行打标签、活跃度评分等线索特征刻画；\n无法通过关键行为和线索分值自动评定线索阶段`,
    icon: CasesPain2,
    id: 'cases-2',
    bgColor: 'yellow',
  },
  {
    title: '线索培育效率无从提速',
    content: `无法自动化培育线索，难以触及客户爽点；\n无法系统化变更线索阶段和自动分配线索`,
    icon: CasesPain3,
    id: 'cases-3',
    bgColor: 'yellow',
  },
  {
    title: '难以促进线索转化',
    content: `长尾线索缺乏定期跟进，沉睡客户缺乏及时激活；\n线索关键行为无法实时传递，错失最佳转化时刻`,
    icon: CasesPain4,
    id: 'cases-4',
    bgColor: 'white',
  },
];

const stepData = {
  titles: [
    `全渠道营销数据整合，\n清晰标记线索来源`,
    `智能筛选组合信息，\n自动刻画线索特征和指标`,
    `自动化精细培育线索，\n加速营销转化`,
    `多元报表分析营销效率，\n营销优化有据可依`,
  ],
  images: [
    Step1,
    Step2,
    Step3,
    Step4,
  ],
  contents: [
    [
      '我们提供微信生态（公众号、企业微信、小程序）、官网、CRM、直播系统等，各类营销渠道的客户属性和营销事件对接。',
      '还为企业提供来源体系构建能力，企业可为每个来源渠道配置详细的媒介、内容、活动、关键词等（最多可配置10个层级），清晰标记线索的初始创建来源。',
      '另外，来源还可被标注在每一个营销事件上，清楚地标记客户每个关键行为（注册、转化等）的来源信息，为营销归因分析奠定数据基础。',
    ],
    [
      '整合全渠道的客户信息（属性、事件、来源等）后，企业可通过刻画特征（标签和群组），或设定指标（线索分值和阶段），来进一步了解线索价值。',
      '我们提供智能筛选组合客户信息的能力，企业可将特定的客户信息设定为判定规则，再通过且/或条件自由组合多条规则，对客户自动进行贴标签、建群组、评定分值、推进线索阶段等操作。',
      '当满足设定条件时，即可自动为线索刻画特征和指标，将线索价值定性定量。',
    ],
    [
      '在为客户标记了特征和指标后，企业就可以对拥有特定特征或指标的客户，做些精准的培育动作。',
      '我们为企业提供了自动流程，帮助企业更高效地培育客户，加速转化。',
      '如，针对Hot Leads结合其需求，自动分配合适的销售。针对Warm Leads结合其内容喜好，自动推送合适的内容，引导客户申请试用。针对Cold Leads结合其之前常互动的平台，通过合适渠道自动推送唤醒信息。',
      '一切的培育引导都刚刚好，每个营销步骤都自动流畅。',
    ],
    [
      '基于渠道来源、线索信息、培育过程都已充分数据化的情况下，企业就可对渠道获客效率、来源分布、转化漏斗等营销效率进行全面分析。',
      '我们为企业提供了营销分析中心，企业可针对特定客户群体进行不同维度的线索分析，对营销事件的变化趋势进行行为分析，对营销活动或流程转化进行漏斗分析等。',
      '上述各类分析报表还能够可视化地呈现在数据看板中，帮助营销人员快速掌握数据变化趋势，用数据指导营销。',
    ],
  ],
};

const description =
  '高新软件行业的线索转化周期非常长，其客户在采购过程中非常理性，且产品单价高，采购频次低，每个客户的培育和长期追踪都非常重要。以上营销特性，会产生以下营销问题：';

export default () => (
  <Layout current="solutions" subCurrent="software">
    <Banner2
      title="高新软件行业"
      Ptitle3="优化营销效果，加速线索转化"
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="咨询行业方案"
    />
    <TitledBoxesWithBg title="营销痛点与挑战" boxDataList={boxDataList} description={description} />
    <StepSolutions data={stepData} stepsTopper={StepsTopper} />
  </Layout>
);
