import React from 'react';
import { Container, Image } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import ImageText from './ImageText';

const CustomTitle = ({ title, num }) => {
  return (
    <div className="step-title">
      <div className="text-row">
        <span className={`step-num step-${num}`} />
        <h3 className="main-title">{title}</h3>
      </div>
    </div>
  );
};

CustomTitle.propTypes = {
  title: PropTypes.string,
  num: PropTypes.string,
};

function StepSolutions({ data, stepsTopper }) {
  return (
    <div id="step-solutions" className="step-solutions">
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3 className="pre-title">Convertlab荟聚正在用</h3>
          <div className="title-bg">
            <div className="background-bar" />
            <h3>“4步法”</h3>
          </div>
        </div>
        <h3 className="title-tail">帮我们的客户解决这些问题</h3>
        <LazyLoad>
          <div className="step-topper">
            <Image src={stepsTopper} alt="StepsTopperImg" className="step-topper-image" />
          </div>
        </LazyLoad>
      </Container>
      <section id="channels" className="channels-list" style={{ padding: 0 }}>
        {[0, 1, 2, 3].map((item) => (
          <ImageText
            key={`step-${item}`}
            image={data.images[item]}
            title={<CustomTitle title={data.titles[item]} num={`${item + 1}`} />}
            descriptionList={data.contents[item]}
            imageSize={6}
            className={data.classNames ? data.classNames[item] : ''}
            position={item % 2 ? 'left' : 'right'}
            bgColor={item % 2 ? 'white' : 'yellow'}
            showDemoBtn
            demoBtnText="咨询行业方案"
          />
        ))}
      </section>
    </div>
  );
}

StepSolutions.propTypes = {
  data: PropTypes.object,
  stepsTopper: PropTypes.string,
};

export default StepSolutions;
